import React, { useState, useEffect } from "react";

import { Modal, Col, Row, Table, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";

import api from "config/api";
import Swal from "sweetalert2";
import Loading from "components/Loading";
import errorRequest from "common/errorRequest";
import ModalImage from "react-modal-image";
import CustomPagination from "components/CustomPagination";

export default function ModalPrint({
  isOpen,
  toggle,
  email,
  cpf,
  productId,
  orderId,
  hasImg,
  approved,
  actualImg,
}) {
  const history = useHistory();
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const limit = 5;
  const [loading, setLoading] = useState(false);
  const [prints, setPrints] = useState([]);

  const allowedMimes = [".png", ".jpeg", ".jpg"];

  useEffect(() => {
    if (isOpen) {
      fetchPrints();
    }
  }, [page, isOpen]);

  async function fetchPrints() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/prints?offset=${
          limit * (page - 1)
        }&limit=${limit}&email=${email}&cpf=${cpf}&product_id=${productId}`
      );
      setPrints(data[0]);
      setTotalPages(data[1]);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function confirmImage(id) {
    Swal.fire({
      icon: "info",
      title: "Atenção",
      text: "Essa opção irá vincular a imagem ao produto. Tem certeza?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
    }).then(async (res) => {
      if (res.value) {
        setLoading(true);
        try {
          await api.put(`print/${id}/${orderId}`);
          window.location.reload(false);
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  function checkImg(path) {
    if (path) {
      const fileType = path.substring(path.indexOf("."));
      if (allowedMimes.includes(fileType)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Modal
        className="modal-dialog-centered modal-lg modal-print"
        isOpen={isOpen}
        toggle={() => toggle()}
      >
        <div className="modal-header">
          <h5 className="modal-title">Escolha de estampa</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {hasImg === 1 && (
            <Row>
              <Col>
                <div>
                  <h3>Estampa atual</h3>
                  <div className="modal-print-atual">
                    <div className="modal-print-atual-img">
                      {actualImg && actualImg.img_path ? (
                        <ModalImage
                          hideZoom
                          className="modalImage-size"
                          small={actualImg.img_url}
                          large={actualImg.img_url}
                        />
                      ) : (
                        <a href={actualImg.img_url}>Download</a>
                      )}
                    </div>
                    <div>
                      <p>
                        Enviado em:{" "}
                        {moment(actualImg.created).format("DD/MM/YYYY")}
                      </p>
                      <p>
                        Escolhido em:{" "}
                        {moment(actualImg.updated_at).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {prints && (
            <Row className="py-4">
              <Col>
                {approved > 0 ? (
                  <h3>Mais estampas enviadas</h3>
                ) : (
                  <h3>Estampas enviadas</h3>
                )}

                <Table className="d-md-table text-center" responsive striped>
                  <thead>
                    <tr>
                      <th className="TableTh th-image">Imagem</th>
                      <th className="TableTh">Cliente</th>
                      <th className="TableTh">Email</th>
                      <th className="TableTh">Telefone</th>
                      <th className="TableTh">CPF</th>
                      <th className="TableTh">Produto</th>
                      <th className="TableTh">Data de envio</th>
                      <th className="TableTh">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prints &&
                      prints.length > 0 &&
                      prints.map((print, index) => (
                        <tr key={print.id}>
                          <td
                            className="TableTd table-img"
                            key={`estampa-${print.id}-${index}`}
                          >
                            {checkImg(print.img_path) ? (
                              <ModalImage
                                hideZoom
                                className="modalImage-size"
                                small={print.img_url}
                                large={print.img_url}
                              />
                            ) : (
                              <a
                                href={print.img_url}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Download
                              </a>
                            )}
                          </td>
                          <td className="TableTd">{print.client_name}</td>
                          <td className="TableTd">{print.client_email}</td>
                          <td className="TableTd">{print.client_phone}</td>
                          <td className="TableTd">{print.client_cpf}</td>
                          <td className="TableTd">{print.product_name}</td>
                          <td className="TableTd">
                            {moment(print.created_at).format("DD/MM/YYYY")}
                          </td>
                          <td className="TableTd">
                            {approved > 0 ? (
                              "Pedido aprovado"
                            ) : (
                              <Button
                                className="modal-print-button"
                                color="primary"
                                onClick={() => confirmImage(print.id)}
                              >
                                Escolher
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {totalPages * limit > limit && (
                  <div className="d-flex my-3 justify-content-center">
                    <CustomPagination
                      page={page}
                      total={totalPages * limit}
                      limit={limit}
                      setPage={setPage}
                      length={prints.length}
                    />
                  </div>
                )}
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    </>
  );
}
