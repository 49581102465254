import UserProfile from "views/admin/UserProfile";

import Orders from "views/admin/Prints/index.jsx";
import Support from "views/admin/Support/index.jsx";
import NotFound from "views/admin/NotFound";

var routes = [
  {
    path: "/user-profile",
    name: "Perfil do usuário",
    invisible: true,
    component: UserProfile,
    layout: "/admin",
  },
  // Manter a home sempre na primeira posição
  {
    path: "/home",
    name: "Página inicial", //Nome dentro do componente
    sidebarname: "Página inicial", //Nome na sidebar
    icon: "fas fa-home", //Ícone da sidebar
    component: Orders,
    layout: "/admin",
  },

  // Manter o support e notfound sempre nas últimas posições respectivamente
  {
    path: "/suporte",
    name: "Suporte", //Nome dentro do componente
    icon: "far fa-question-circle", //Ícone da sidebar
    component: Support,
    layout: "/admin",
  },
  {
    path: "*",
    invisible: true,
    component: NotFound,
    layout: "/admin",
  },
];
export default routes;
