import React from "react";

import { Modal, Col, Row, Table } from "reactstrap";

import maskCpfCnpj from "common/maskCpfCnpj.js";

export default function ModalClient({ isOpen, toggle, id, name, email, cpf }) {
  const nuvemShopOriginalDomain = localStorage.getItem(
    "nuvemShopOriginalDomain"
  );
  return (
    <>
      <Modal
        className="modal-dialog-centered modal-lg"
        isOpen={isOpen}
        toggle={() => toggle()}
      >
        <div className="modal-header">
          <h5 className="modal-title">Dados do cliente</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col>
              <Table className="d-md-table text-center" responsive striped>
                <thead>
                  <tr>
                    <th className="TableTh text-left">Cliente</th>
                    <th className="TableTh text-left">Nome</th>
                    <th className="TableTh">Email</th>
                    <th className="TableTh">CPF</th>
                  </tr>
                </thead>
                <tbody>
                  {2 > 1 ? (
                    <tr>
                      <td className="TableTd text-left">
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`https://${nuvemShopOriginalDomain}/admin/products/${id}`}
                        >
                          Abrir <i className="fas fa-external-link-alt" />
                        </a>
                      </td>
                      <td className="TableTd col-4 text-left">{name}</td>
                      <td className="TableTd">{email}</td>
                      <td className="TableTd">{cpf && maskCpfCnpj(cpf)}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="4">Nenhum registro encontrado...</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
