import React, { useState, useEffect } from "react";

// import Swal from "sweetalert2";

import { useHistory } from "react-router-dom";

import "./styles.scss";
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  Table,
} from "reactstrap";

import Loading from "components/Loading";

import api from "config/api";
import Page from "components/Page";
import errorRequest from "common/errorRequest";
import Swal from "sweetalert2";
import CustomPagination from "components/CustomPagination";

import ModalProducts from "./ModalProducts";
import ModalClient from "./ModalClient";
import ModalPrint from "./ModalPrint";

export default function Orders() {
  const nuvemShopOriginalDomain = localStorage.getItem(
    "nuvemShopOriginalDomain"
  );
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(4);
  const [orders, setOrders] = useState([]);
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState();
  const [clientId, setClientId] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientCpf, setClientCpf] = useState();
  const [printClientEmail, setPrintClientEmail] = useState();
  const [printClientCpf, setPrintClientCpf] = useState();
  const [printProductId, setPrintProductId] = useState("");
  const [printOrderId, setPrintOrderId] = useState("");
  const [printHasImg, setPrintHasImg] = useState();
  const [printApproved, setPrintApproved] = useState();
  const [actualimg, setActualImg] = useState([]);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState();
  const [page, setPage] = useState(1);
  const limit = 10;
  const [modalProducts, setModalProducts] = useState(false);
  const [modalClient, setModalClient] = useState(false);
  const [modalPrint, setModalPrint] = useState(false);

  const allowedMimes = [".png", ".jpeg", ".jpg"];

  useEffect(() => {
    fetchOrders();
  }, [page, status]);

  async function fetchOrders() {
    setLoading(true);
    try {
      if (search) {
        const { data } = await api.get(
          `/order?status=${status}&search=${search}&limit=${limit}&offset=${
            limit * (page - 1)
          }`
        );
        setOrders(data[0]);
        setTotalPages(data[1]);
      } else {
        const { data } = await api.get(
          `/orders?status=${status}&limit=${limit}&offset=${limit * (page - 1)}`
        );
        setOrders(data[0]);
        setTotalPages(data[1]);
      }
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function productInfo(id, name, price) {
    setProductId(id);
    setProductName(name);
    setProductPrice(price);
    setModalProducts(!modalProducts);
  }

  function clientInfo(id, name, email, cpf) {
    setClientId(id);
    setClientName(name);
    setClientEmail(email);
    setClientCpf(cpf);
    setModalClient(!modalClient);
  }

  function printInfo(email, cpf, productId, orderId, hasImg, approved, print) {
    setPrintClientEmail(email);
    setPrintClientCpf(cpf);
    setPrintProductId(productId);
    setPrintOrderId(orderId);
    setPrintHasImg(hasImg);
    setPrintApproved(approved);
    setActualImg(print);
    setModalPrint(!modalPrint);
  }

  function checkImg(path) {
    if (path) {
      const fileType = path.substring(path.indexOf("."));
      if (allowedMimes.includes(fileType)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function refuse(id) {
    Swal.fire({
      icon: "info",
      title: "Atenção",
      text: "Essa opção irá cancelar e arquivar o pedido. Confirme apenas se você já tiver entrado em contato com o cliente e realizado o estorno de sua compra.",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
    }).then(async (res) => {
      if (res.value) {
        setLoading(true);
        try {
          await api.put(`/order/archived/${id}`);
          window.location.reload(false);
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  function approveOrder(orderId, approved) {
    if (approved === 0) {
      Swal.fire({
        icon: "info",
        title: "Atenção",
        text: "Essa opção irá confirmar o pedido, relacionando a imagem escolhida ao produto. Cheque as escolhas antes de confirmar.",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      }).then(async (res) => {
        if (res.value) {
          setLoading(true);
          try {
            await api.put(`/order/approved`, {
              id: orderId,
              approved: 1,
            });
            window.location.reload(false);
          } catch (error) {
            errorRequest(history, error);
          }
          setLoading(false);
        }
      });
    } else {
      Swal.fire({
        icon: "info",
        title: "Atenção",
        text: "Essa opção irá desfazer a confirmação o pedido, tem certeza disso?",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      }).then(async (res) => {
        if (res.value) {
          setLoading(true);
          try {
            await api.put(`/order/approved`, {
              id: orderId,
              approved: 0,
            });
            window.location.reload(false);
          } catch (error) {
            errorRequest(history, error);
          }
          setLoading(false);
        }
      });
    }
  }

  return (
    <Page loading={loading}>
      <>
        {loading && <Loading />}
        <ModalProducts
          isOpen={modalProducts}
          toggle={() => setModalProducts(!modalProducts)}
          id={productId}
          name={productName}
          price={productPrice}
        />
        <ModalClient
          isOpen={modalClient}
          toggle={() => setModalClient(!modalClient)}
          id={clientId}
          name={clientName}
          email={clientEmail}
          cpf={clientCpf}
        />
        <ModalPrint
          isOpen={modalPrint}
          toggle={() => setModalPrint(!modalPrint)}
          email={printClientEmail}
          cpf={printClientCpf}
          productId={printProductId}
          orderId={printOrderId}
          actualImg={actualimg}
          hasImg={printHasImg}
          approved={printApproved}
        />
        <Row className="mb-4">
          <Col md="4">
            <InputGroup>
              <Input
                className="table-search-input"
                placeholder="Buscar..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClick={() => fetchOrders()}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    fetchOrders();
                  }
                }}
              />
              <InputGroupAddon color="primary" addonType="append">
                <Button
                  className="table-search-button"
                  onClick={() => fetchOrders()}
                >
                  <i className="fas fa-search" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Input
                type="select"
                onChange={(e) => setStatus(+e.target.value)}
                value={status}
              >
                <option key="order-status-4" value={4}>
                  Todos os pedidos
                </option>
                <option key="order-status-0" value={0}>
                  Aguardando
                </option>
                <option key="order-status-1" value={1}>
                  Aprovados
                </option>
                <option key="order-status-2" value={2}>
                  Arquivados
                </option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              className="d-md-table sales-customers-table text-center"
              responsive
              striped
            >
              <thead>
                <tr>
                  <th className="TableTh">Pedido</th>
                  <th className="TableTh">Cliente</th>
                  <th className="TableTh">CPF</th>
                  <th className="TableTh">Imagem</th>
                  <th className="TableTh">Situação</th>
                  <th className="TableTh">Produto</th>
                  <th className="TableTh">Ações</th>
                </tr>
              </thead>
              <tbody>
                {orders && orders.length === 0 ? (
                  <tr>
                    <td colSpan="7">Não foi encontrado nenhum registro...</td>
                  </tr>
                ) : (
                  orders &&
                  orders.map((order) => (
                    <tr key={`${order.nuvemshop_order_id}-${order.id}`}>
                      <td className="TableTd">
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={`https://${nuvemShopOriginalDomain}/admin/orders/${order.nuvemshop_order_id}`}
                        >
                          {order.store_order_id}{" "}
                          <i className="fas fa-external-link-alt" />
                        </a>
                      </td>
                      <td className="TableTd">{order.client_name}</td>
                      <td className="TableTd">{order.client_cpf}</td>
                      <td className="TableTd">
                        {/* {order.has_img === 0 && (
                          <span>Estampa não recebida</span>
                        )} */}
                        {/* {order.has_img === 0 && order.print && ( */}
                        {order.has_img === 0 && order.archived === 0 && (
                          <button
                            className="prints-button-img table-more"
                            onClick={() =>
                              printInfo(
                                order.client_email,
                                order.client_cpf,
                                order.nuvemshop_product_id,
                                order.id,
                                order.has_img,
                                order.approved,
                                order.print
                              )
                            }
                          >
                            Escolher Estampa
                          </button>
                        )}
                        {order.has_img > 0 && (
                          <div
                            className="prints-img"
                            onClick={() =>
                              printInfo(
                                order.client_email,
                                order.client_cpf,
                                order.nuvemshop_product_id,
                                order.id,
                                order.has_img,
                                order.approved,
                                order.print
                              )
                            }
                          >
                            {checkImg(order.print.img_path) ? (
                              <img
                                src={order.print.img_url}
                                alt=""
                                className="prints-img-modal"
                              />
                            ) : (
                              <span className="table-more">Arquivo</span>
                            )}
                          </div>
                        )}
                      </td>
                      <td className={`TableTd`}>
                        {order.approved === 1
                          ? "Aprovado"
                          : order.archived === 1
                          ? "Arquivado"
                          : "Aguardando"}
                      </td>
                      <td className="TableTd">
                        <span
                          className="table-more"
                          onClick={() =>
                            productInfo(
                              order.nuvemshop_product_id,
                              order.product_name,
                              order.product_price
                            )
                          }
                        >
                          Veja mais
                        </span>
                      </td>
                      <td className="TableTd">
                        <div className="table-action">
                          {order.print &&
                            order.approved === 0 &&
                            order.archived === 0 && (
                              <>
                                <div
                                  onClick={() =>
                                    approveOrder(order.id, order.approved)
                                  }
                                  className="action-button-hover table-action-button-edit"
                                >
                                  <div className="button-baloon">Aprovar</div>
                                  <i className="fas fa-check" />
                                </div>
                                <div
                                  onClick={() => refuse(order.id)}
                                  className="action-button-hover table-action-button-delete"
                                >
                                  <div className="button-baloon">Recusar</div>
                                  <i className="fas fa-times" />
                                </div>
                              </>
                            )}
                          {order.approved === 1 && order.archived === 0 && (
                            <>
                              <div
                                onClick={() =>
                                  approveOrder(order.id, order.approved)
                                }
                                className="action-button-hover table-action-button-return"
                              >
                                <div className="button-baloon">Desfazer</div>
                                <i className="fas fa-undo" />
                              </div>
                            </>
                          )}
                          {!order.print && order.archived === 0 && (
                            <>
                              <div
                                onClick={() =>
                                  clientInfo(
                                    order.nuvemshop_client_id,
                                    order.client_name,
                                    order.client_email,
                                    order.client_cpf
                                  )
                                }
                                className="action-button-hover table-action-button-contact"
                              >
                                <div className="button-baloon">Contato</div>
                                <i className="far fa-envelope" />
                              </div>
                              <div
                                onClick={() => refuse(order.id)}
                                className="action-button-hover table-action-button-delete"
                              >
                                <div className="button-baloon">Recusar</div>
                                <i className="fas fa-times" />
                              </div>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {totalPages * limit > limit && (
              <div className="d-flex my-3 justify-content-center">
                <CustomPagination
                  page={page}
                  total={totalPages * limit}
                  limit={limit}
                  setPage={setPage}
                  length={orders.length}
                />
              </div>
            )}
          </Col>
        </Row>
      </>
    </Page>
  );
}
